<template>
  <div>
    <div
      class="row mt-5 w-85 big-div"
      style="position: relative; z-index: 100"
      :style="
        getPageDesign() +
        `zoom:${this.pageZoomValue ? this.pageZoomValue : 90}%;`
      "
    >
      <div
        class="bg"
        v-if="
          (pageDesign.pageType == 'resultPage' &&
            pageDesign.imageBlock.src !== '') ||
          (pageDesign.pageType == 'noResultPage' &&
            pageDesign.imageBlock.noResultSrc !== '' &&
            getImageAlign == 'no')
        "
        :style="
          getbackgroundImage(
            IsImageAdded,
            pageDesign.imageBlock,
            getImageAlign,
            isDesktopView
          ) + getBgDivStyles()
        "
      ></div>
      <div
        class="col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0"
        v-show="showLeftImage && pageDesign.pageType !== 'resultPage'"
        :class="{
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division': isDesktopView,
        }"
        :style="
          calculateImageWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive
          )
        "
      >
        <div
          @click="activeImageCustomize"
          :class="isDesktopView ? 'image-division' : ''"
        >
          <div :style="height100(isDesktopView)">
            <!-- <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.8">
                <path d="M164.815 35.1852V164.815H35.1851V35.1852H164.815ZM164.815 16.6667H35.1851C25 16.6667 16.6666 25 16.6666 35.1852V164.815C16.6666 175 25 183.333 35.1851 183.333H164.815C175 183.333 183.333 175 183.333 164.815V35.1852C183.333 25 175 16.6667 164.815 16.6667ZM119.815 98.7037L92.037 134.537L72.2222 110.556L44.4444 146.296H155.556L119.815 98.7037Z" fill="white"/>
                </g>
                </svg> -->

            <LazyImage
              :key="ImgBlockImageSrc"
              :style="
                getUniversalImageStyle(
                  isDesktopView,
                  pageDesign.imageBlock.style,
                  IsImageVariableWidthActive,
                  pageDesign.imageBlock
                ) +
                getImageOpacity(pageDesign.imageBlock.style) +
                getImageRotation(pageDesign.imageBlock.style)
              "
              :class="[
                `pic-${getSelectedPage.order}`,
                ImgBlockImageSrc == '/images/default_start_page.png'
                  ? 'default-bg'
                  : '',
              ]"
              :src="MainImgSrc(ImgBlockImageSrc)"
              alt
            />
          </div>
        </div>
        <button id="clear" class="hide close-icon" @click="deleteImage">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
              fill="black"
              fill-opacity="0.5"
            />
            <path
              d="M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z"
              fill="white"
            />
          </svg>
        </button>
      </div>

      <!-- Main Section -->
      <div
        :class="!IsImageAdded ? 'col-12' : imageAddedLayout"
        :style="
          getMainPaddingCss(pageDesign.pageStyles, isDesktopView) +
          getScrollCSS(
            pageDesign.pageStyles,
            isDesktopView,
            pageDesign.imageBlock.style,
            pageDesign.pageType
          ) +
          calculateContentWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive,
            pageDesign.pageStyles,
            pageDesign.pageType
          )
        "
      >
        <div class="">
          <div>
            <div v-if="pageDesign.pageType == 'resultPage'">
              <draggable
                v-model="pageDesignBlocks"
                class="dragArea list-group"
                group="actions"
                v-bind="dragOptions"
                chosen-class="chosen"
                drag-class="drag"
                @start="dragging = true"
                @end="dragging = false"
                style="cursor: move"
              >
                <div
                  v-for="(pro, ind) in pageDesign.blocksArray"
                  :key="'preview' + ind"
                  draggable="false"
                  :class="dragging ? 'dragElementBorder' : null"
                  @drop="handleElementDrop($event, ind)"
                  @dragover.prevent
                  @dragenter.prevent
                  @click="changeCustomize(ind)"
                  @mouseover="addHoverPorperty(ind)"
                  @mouseleave="removeHoverPorperty(ind)"
                >
                  <div
                    id="preview"
                    :style="getHeightWidth(pro.style) + getDivBorderCss(pro)"
                    class="content"
                    style="position: relative"
                  >
                    <EditorAction
                      v-if="pro.customizeStatus"
                      :deleteCondition="getDeleteButtonCondition(pro)"
                      :currElementIndex="ind"
                      :currElementType="pro.type"
                    />

                    <!-- <VueDragResize v-if="pro.type == 'text'"  :isActive="pro.customizeStatus" :w="pro.style.width" :h="pro.style.height" v-on:resizing="resize"  :isDraggable="false"> -->
                    <div
                      v-if="pro.type == 'text'"
                      :style="
                        getColorCss(pro.style) +
                        getPaddingCss(pro.style) +
                        getMarginCss(pro.style, isDesktopView) +
                        getColorWithOpacity(pro.style) +
                        getTextRotate(pro.style) +
                        getLineHeight(pro.style) +
                        getTextAlign(pro.style) +
                        getFontStyle(pro.style, isDesktopView)
                      "
                      v-html="getEditorPersonalisedValueStyle(pro.content)"
                    ></div>
                    <div
                      v-if="pro.type == 'html'"
                      :style="
                        getMarginCss(pro.style, isDesktopView) +
                        getColorWithOpacity(pro.style) +
                        getTextRotate(pro.style) +
                        getTextAlign(pro.style)
                      "
                    >
                      <HtmlElement :pro="pro" :isDesktopView="isDesktopView" />
                    </div>
                    <div v-if="pro.type == 'imageComp'">
                      <InlineImageComp
                        :pro="pro"
                        :isDesktopView="isDesktopView"
                      />
                    </div>

                    <!-- </VueDragResize> -->
                    <!-- Products -->
                    <div v-if="pro.type == 'product'">
                      <!-- Detail Only View  start-->
                      <div class="row" v-if="getViewType == 'detailOnly'">
                        <div v-if="pro.showListView" class="row w-100 m-0 p-0">
                          <div
                            class="col-12 mb-2"
                            v-for="(product, index) in getProductsList"
                            :key="index"
                          >
                            <div
                              class="row m-0 p-0 mb-3"
                              :style="
                                detailProductContainerStyle(isDesktopView) +
                                getCardBackground(pro.style) +
                                getCardBorderCSS(pro.style)
                              "
                            >
                              <div
                                class="p-0 m-0"
                                :class="
                                  getProductCarouselImageClass(isDesktopView)
                                "
                                :style="
                                  getProductCarouselImageStyle(isDesktopView)
                                "
                              >
                                <div
                                  class="w-100 h-100"
                                  style="position: relative"
                                >
                                  <LazyImage
                                    :src="product.image"
                                    alt=""
                                    :style="
                                      getDetailProductImageFit(pro.style) +
                                      getDetailProductImageRatio(pro.style) +
                                      getImageborderRadius(pro.style)
                                    "
                                  />

                                  <!--Product Rank Start -->
                                  <div
                                    v-if="
                                      pro.showProductRank &&
                                      index <= pro.productRankOption.length - 1
                                    "
                                    style="
                                      position: absolute;
                                      top: -2%;
                                      left: 50%;
                                      transform: translateX(-50%);
                                      z-index: 100;
                                    "
                                    class="d-flex justify-content-center"
                                  >
                                    <p
                                      style="text-align: center"
                                      :style="getProductRankCss(pro.style)"
                                    >
                                      <span>{{
                                        pro.productRankOption[index].text
                                      }}</span>
                                    </p>
                                  </div>
                                  <!--Product Rank end -->
                                </div>
                              </div>
                              <div
                                class="h-100 w-100 d-flex flex-column"
                                :style="
                                  getPagePadding(pro.style, isDesktopView)
                                "
                                :class="isDesktopView ? ' col-md-6' : 'col-12'"
                              >
                                <div class="d-flex flex-column flex-grow-1">
                                  <!-- Title & price -->
                                  <div>
                                    <p :style="getTitleStyles(pro.style)">
                                      {{
                                        getProductInfoDetail(
                                          pro.useProductVariant,
                                          product,
                                          "title"
                                        )
                                      }}
                                    </p>
                                  </div>
                                  <!-- Product Price , features  -->
                                  <div class="">
                                    <div
                                      :style="
                                        getDescriptionStyles(isDesktopView)
                                      "
                                    >
                                      <div v-if="!pro.hideDescription">
                                        <!-- normal Description Start -->
                                        <div
                                          :style="
                                            getDescriptionStyles(isDesktopView)
                                          "
                                          v-if="!pro.useBulletDescription"
                                        >
                                          <p
                                            class="mb-0"
                                            :style="getPriceColor(pro.style)"
                                          >
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore
                                            et dolore magna aliqua. Ut enim ad
                                            minim veniam, quis nostrud
                                            exercitation ullamco laboris nisi ut
                                            aliquip ex ea commodo consequat. ng
                                            elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna
                                            aliqua. Ut enim ad minim veniam,
                                            quis nostrud exercitation ullamco
                                            laboris nisi ut aliquip ex ea
                                            commodo consequat. Lorem ipsum dolor
                                            sit amet, consectetur adipiscing
                                            elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna
                                            aliqua. Ut enim ad minim veniam,
                                            quis nostrud exercitation ullamco
                                            laboris nisi ut aliquip ex ea
                                            commodo consequat. ng elit, sed do
                                            eiusmod tempor incididunt ut labore
                                            et dolore magna aliqua. Ut enim ad
                                            minim veniam, quis nostrud
                                            exercitation ullamco laboris nisi ut
                                            aliquip ex ea commodo consequat
                                          </p>
                                        </div>
                                        <!-- normal Description End -->

                                        <!-- Bullet Description Start -->
                                        <div v-else>
                                          <div
                                            v-for="(
                                              features, index2
                                            ) in product.productAdvantage"
                                            :key="index2"
                                          >
                                            <div
                                              class="d-flex align-items-center mt-1"
                                              :style="
                                                getBulletStyles(pro.style)
                                              "
                                            >
                                              <div class="mr-3">
                                                <i
                                                  class="fa"
                                                  :class="
                                                    pro.selectedBulletIcon
                                                  "
                                                ></i>
                                              </div>
                                              <span>{{ features.text }}</span>
                                            </div>
                                          </div>
                                        </div>

                                        <!-- Bullet Description End -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="mt-auto">
                                  <p
                                    class="m-0 p-0"
                                    v-if="!isHidePrice"
                                    :style="getPriceColor(pro.style)"
                                  >
                                    <span
                                      v-if="currencyPos == 'left'"
                                      :class="currencySpace ? 'mr-1' : null"
                                      >{{ currentCurrency }}</span
                                    >{{
                                      getProductInfoDetail(
                                        pro.useProductVariant,
                                        product,
                                        "price"
                                      )
                                    }}<span
                                      v-if="currencyPos == 'right'"
                                      :class="currencySpace ? 'ml-1' : null"
                                      >{{ currentCurrency }}</span
                                    >
                                  </p>

                                  <div class="mt-2">
                                    <div
                                      class="pb-2"
                                      v-if="
                                        pro.useProductVariant &&
                                        product &&
                                        product.variants
                                      "
                                    >
                                      <VaraintSelectComp
                                        :pro="pro"
                                        :product="product"
                                      />
                                    </div>
                                    <button
                                      v-if="pro.useAddToCart"
                                      :style="getProductButtonCss(pro.style)"
                                    >
                                      {{
                                        pro.useAddToCartText || "Add to Cart"
                                      }}
                                    </button>
                                    <button
                                      v-else
                                      :style="getProductButtonCss(pro.style)"
                                    >
                                      {{ pro.buttonText }}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12" v-else>
                          <carousel
                            class="ProductCarousel"
                            :perPage="1"
                            :paginationEnabled="
                              checkProductLength(getProductsList)
                            "
                            :navigationEnabled="
                              checkProductLength(getProductsList)
                            "
                          >
                            <slide
                              v-for="(product, index) in getProductsList"
                              :key="index"
                            >
                              <div
                                class="row m-0 p-0"
                                :style="
                                  detailProductContainerStyle(isDesktopView) +
                                  getCardBackground(pro.style) +
                                  getCardBorderCSS(pro.style)
                                "
                              >
                                <div
                                  class="p-0 m-0"
                                  :class="
                                    getProductCarouselImageClass(isDesktopView)
                                  "
                                  :style="
                                    getProductCarouselImageStyle(isDesktopView)
                                  "
                                >
                                  <div
                                    class="w-100 h-100"
                                    style="position: relative"
                                  >
                                    <LazyImage
                                      :src="product.image"
                                      alt=""
                                      :style="
                                        getDetailProductImageFit(pro.style) +
                                        getDetailProductImageRatio(pro.style) +
                                        getImageborderRadius(pro.style)
                                      "
                                    />
                                    <!--Product Rank Start -->
                                    <div
                                      v-if="
                                        pro.showProductRank &&
                                        index <=
                                          pro.productRankOption.length - 1
                                      "
                                      style="
                                        position: absolute;
                                        top: 0%;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        z-index: 100;
                                      "
                                      class="d-flex justify-content-center"
                                    >
                                      <p
                                        style="text-align: center"
                                        :style="getProductRankCss(pro.style)"
                                      >
                                        <!-- <span v-if="index == 0">1st</span>
                          <span v-if="index == 1">2nd</span>
                          <span v-if="index == 2">3rd</span> -->
                                        <span>{{
                                          pro.productRankOption[index].text
                                        }}</span>
                                      </p>
                                    </div>
                                    <!--Product Rank end -->
                                  </div>
                                </div>
                                <div
                                  class="h-100 w-100 d-flex flex-column"
                                  :style="
                                    getPagePadding(pro.style, isDesktopView)
                                  "
                                  :class="
                                    isDesktopView ? ' col-md-6' : 'col-12'
                                  "
                                >
                                  <div class="d-flex flex-column flex-grow-1">
                                    <!-- Title & price -->
                                    <div class="mb-2">
                                      <p
                                        class="mb-0"
                                        :style="getTitleStyles(pro.style)"
                                      >
                                        {{
                                          getProductInfoDetail(
                                            pro.useProductVariant,
                                            product,
                                            "title"
                                          )
                                        }}
                                      </p>
                                      <!-- Rating Div start -->
                                      <div
                                        v-if="
                                          product.productReview &&
                                          isJudgeMeInstalled
                                        "
                                        class="Review"
                                        v-html="product.productReview"
                                        :style="getPriceColor(pro.style)"
                                      ></div>
                                      <!-- Rating Div end -->
                                    </div>
                                    <!-- Product Price , features  -->
                                    <div class="">
                                      <div
                                        :style="
                                          getDescriptionStyles(isDesktopView)
                                        "
                                      >
                                        <div v-if="!pro.hideDescription">
                                          <!-- normal Description Start -->
                                          <div
                                            :style="
                                              getDescriptionStyles(
                                                isDesktopView
                                              )
                                            "
                                            v-if="!pro.useBulletDescription"
                                          >
                                            <p
                                              class="mb-0"
                                              :style="getPriceColor(pro.style)"
                                            >
                                              Lorem ipsum dolor sit amet,
                                              consectetur adipiscing elit, sed
                                              do eiusmod tempor incididunt ut
                                              labore et dolore magna aliqua. Ut
                                              enim ad minim veniam, quis nostrud
                                              exercitation ullamco laboris nisi
                                              ut aliquip ex ea commodo
                                              consequat. ng elit, sed do eiusmod
                                              tempor incididunt ut labore et
                                              dolore magna aliqua. Ut enim ad
                                              minim veniam, quis nostrud
                                              exercitation ullamco laboris nisi
                                              ut aliquip ex ea commodo
                                              consequat. Lorem ipsum dolor sit
                                              amet, consectetur adipiscing elit,
                                              sed do eiusmod tempor incididunt
                                              ut labore et dolore magna aliqua.
                                              Ut enim ad minim veniam, quis
                                              nostrud exercitation ullamco
                                              laboris nisi ut aliquip ex ea
                                              commodo consequat. ng elit, sed do
                                              eiusmod tempor incididunt ut
                                              labore et dolore magna aliqua. Ut
                                              enim ad minim veniam, quis nostrud
                                              exercitation ullamco laboris nisi
                                              ut aliquip ex ea commodo consequat
                                            </p>
                                          </div>
                                          <!-- normal Description End -->

                                          <!-- Bullet Description Start -->
                                          <div v-else>
                                            <div
                                              v-for="(
                                                features, index2
                                              ) in product.productAdvantage"
                                              :key="index2"
                                            >
                                              <div
                                                class="d-flex align-items-center mt-1"
                                                :style="
                                                  getBulletStyles(pro.style)
                                                "
                                              >
                                                <div class="mr-3">
                                                  <i
                                                    class="fa"
                                                    :class="
                                                      pro.selectedBulletIcon
                                                    "
                                                  ></i>
                                                </div>
                                                <span>{{ features.text }}</span>
                                              </div>
                                            </div>
                                          </div>

                                          <!-- Bullet Description End -->
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="mt-auto">
                                    <p
                                      class="m-0 p-0"
                                      v-if="!isHidePrice"
                                      :style="getPriceColor(pro.style)"
                                    >
                                      <span
                                        v-if="currencyPos == 'left'"
                                        :class="currencySpace ? 'mr-1' : null"
                                        >{{ currentCurrency }}</span
                                      >{{
                                        getProductInfoDetail(
                                          pro.useProductVariant,
                                          product,
                                          "price"
                                        )
                                      }}<span
                                        v-if="currencyPos == 'right'"
                                        :class="currencySpace ? 'ml-1' : null"
                                        >{{ currentCurrency }}</span
                                      >
                                    </p>

                                    <div class="mt-2">
                                      <div
                                        class="pb-2"
                                        v-if="
                                          pro.useProductVariant &&
                                          product &&
                                          product.variants
                                        "
                                      >
                                        <VaraintSelectComp
                                          :pro="pro"
                                          :product="product"
                                        />
                                      </div>
                                      <button
                                        v-if="pro.useAddToCart"
                                        :style="getProductButtonCss(pro.style)"
                                      >
                                        {{
                                          pro.useAddToCartText || "Add to Cart"
                                        }}
                                      </button>
                                      <button
                                        v-else
                                        :style="getProductButtonCss(pro.style)"
                                      >
                                        {{ pro.buttonText }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </slide>
                          </carousel>
                        </div>
                      </div>
                      <!-- Detail Only View  end-->

                      <!-- Detail- List View start-->

                      <div class="row" v-if="getViewType == 'detailList'">
                        <div class="col-12 mb-3">
                          <div
                            class="row m-0 p-0"
                            :style="
                              detailProductContainerStyle(isDesktopView) +
                              getCardBackground(pro.style) +
                              getCardBorderCSS(pro.style)
                            "
                          >
                            <div
                              class="p-0 m-0"
                              :class="
                                getProductCarouselImageClass(isDesktopView)
                              "
                              :style="
                                getProductCarouselImageStyle(isDesktopView)
                              "
                            >
                              <div class="w-100 h-100 position-relative">
                                <LazyImage
                                  :src="SampleProducts[0].image"
                                  alt=""
                                  :style="
                                    getDetailProductImageFit(pro.style) +
                                    getDetailProductImageRatio(pro.style) +
                                    getImageborderRadius(pro.style)
                                  "
                                />
                                <!--Product Rank Start -->
                                <div
                                  v-if="pro.showProductRank"
                                  style="
                                    position: absolute;
                                    top: -2%;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    z-index: 100;
                                  "
                                  class="d-flex justify-content-center"
                                >
                                  <p
                                    style="text-align: center"
                                    :style="getProductRankCss(pro.style)"
                                  >
                                    <!-- <span >1st</span> -->
                                    <span>{{
                                      pro.productRankOption[0].text
                                    }}</span>
                                  </p>
                                </div>
                                <!--Product Rank end -->
                              </div>
                            </div>
                            <div
                              class="h-100 w-100 d-flex flex-column"
                              :style="getPagePadding(pro.style, isDesktopView)"
                              :class="
                                getProductCarouselImageClass(isDesktopView)
                              "
                            >
                              <div class="d-flex flex-column flex-grow-1">
                                <!-- Title & price -->
                                <div class="mb-2">
                                  <p
                                    class="mb-0"
                                    :style="getTitleStyles(pro.style)"
                                  >
                                    {{
                                      getProductInfoDetail(
                                        pro.useProductVariant,
                                        SampleProducts[0],
                                        "title"
                                      )
                                    }}
                                  </p>

                                  <!-- Rating Div start -->
                                  <div
                                    v-if="
                                      SampleProducts[0].productReview &&
                                      isJudgeMeInstalled
                                    "
                                    class="Review"
                                    v-html="SampleProducts[0].productReview"
                                    :style="getPriceColor(pro.style)"
                                  ></div>
                                  <!-- Rating Div end -->
                                </div>
                                <!-- Product Price , features  -->
                                <div class="">
                                  <div
                                    :style="getDescriptionStyles(isDesktopView)"
                                  >
                                    <div v-if="!pro.hideDescription">
                                      <!-- normal Description Start -->
                                      <div
                                        :style="
                                          getDescriptionStyles(isDesktopView)
                                        "
                                        v-if="!pro.useBulletDescription"
                                      >
                                        <p
                                          class="mb-0"
                                          :style="getPriceColor(pro.style)"
                                        >
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut labore et
                                          dolore magna aliqua. Ut enim ad minim
                                          veniam, quis nostrud exercitation
                                          ullamco laboris nisi ut aliquip ex ea
                                          commodo consequat. ng elit, sed do
                                          eiusmod tempor incididunt ut labore et
                                          dolore magna aliqua. Ut enim ad minim
                                          veniam, quis nostrud exercitation
                                          ullamco laboris nisi ut aliquip ex ea
                                          commodo consequat. Lorem ipsum dolor
                                          sit amet, consectetur adipiscing elit,
                                          sed do eiusmod tempor incididunt ut
                                          labore et dolore magna aliqua. Ut enim
                                          ad minim veniam, quis nostrud
                                          exercitation ullamco laboris nisi ut
                                          aliquip ex ea commodo consequat. ng
                                          elit, sed do eiusmod tempor incididunt
                                          ut labore et dolore magna aliqua. Ut
                                          enim ad minim veniam, quis nostrud
                                          exercitation ullamco laboris nisi ut
                                          aliquip ex ea commodo consequat
                                        </p>
                                      </div>
                                      <!-- normal Description End -->

                                      <!-- Bullet Description Start -->
                                      <div v-else>
                                        <div
                                          v-for="(
                                            features, index2
                                          ) in SampleProducts[0]
                                            .productAdvantage"
                                          :key="index2"
                                        >
                                          <div
                                            class="d-flex align-items-center mt-1"
                                            :style="getBulletStyles(pro.style)"
                                          >
                                            <div class="mr-3">
                                              <i
                                                class="fa"
                                                :class="pro.selectedBulletIcon"
                                              ></i>
                                            </div>
                                            <span>{{ features.text }}</span>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- Bullet Description End -->
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="mt-auto">
                                <p
                                  class="m-0 p-0 mt-3"
                                  v-if="!isHidePrice"
                                  :style="getPriceColor(pro.style)"
                                >
                                  <span
                                    v-if="currencyPos == 'left'"
                                    :class="currencySpace ? 'mr-1' : null"
                                    >{{ currentCurrency }}</span
                                  >{{
                                    getProductInfoDetail(
                                      pro.useProductVariant,
                                      SampleProducts[0],
                                      "price"
                                    )
                                  }}<span
                                    v-if="currencyPos == 'right'"
                                    :class="currencySpace ? 'ml-1' : null"
                                    >{{ currentCurrency }}</span
                                  >
                                </p>

                                <div class="mt-2">
                                  <div
                                    class="pb-2"
                                    v-if="
                                      pro.useProductVariant &&
                                      SampleProducts[0] &&
                                      SampleProducts[0].variants
                                    "
                                  >
                                    <VaraintSelectComp
                                      :pro="pro"
                                      :product="SampleProducts[0]"
                                    />
                                  </div>
                                  <button
                                    v-if="pro.useAddToCart"
                                    :style="getProductButtonCss(pro.style)"
                                  >
                                    {{ pro.useAddToCartText || "Add to Cart" }}
                                  </button>
                                  <button
                                    v-else
                                    :style="getProductButtonCss(pro.style)"
                                  >
                                    {{ pro.buttonText }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 mb-3">
                          <p :style="getMoreOptionStyles(pro.style)">
                            {{ getMoreOptionText(pro.moreOptionText) }}
                          </p>
                        </div>
                        <div
                          :class="
                            isDesktopView
                              ? `col-${12 / pro.productsPerPage}`
                              : `col-${12 / pro.productsPerPageMobile}`
                          "
                          class="mb-3"
                          v-for="(product, index) in getDetailListProducts(
                            SampleProducts
                          )"
                          :key="index"
                          v-show="index < displayProductsLength(pro)"
                        >
                          <div
                            class="card shadow-sm d-flex flex-column h-100 mx-auto position-relative"
                            :style="
                              getCardBackground(pro.style) +
                              getCardBorderCSS(pro.style) +
                              getPagePadding(pro.style, isDesktopView) +
                              getFontFamily(pro.style) +
                              ListProductMaxWidth(
                                isDesktopView
                                  ? pro.productsPerPage
                                  : pro.productsPerPageMobile
                              )
                            "
                          >
                            <div
                              v-if="
                                pro.showProductRank &&
                                index <= pro.productRankOption.length - 1
                              "
                              style="
                                position: absolute;
                                top: -2%;
                                left: 50%;
                                transform: translateX(-50%);
                                z-index: 100;
                              "
                              class="d-flex justify-content-center"
                            >
                              <p
                                style="text-align: center"
                                :style="getProductRankCss(pro.style)"
                                v-if="pro.productRankOption[index + 1]"
                              >
                                <!-- <span v-if="index == 0">2nd</span>
                          <span v-if="index == 1">3rd</span> -->
                                <span>{{
                                  pro.productRankOption[index + 1].text
                                }}</span>
                              </p>
                            </div>

                            <!-- <div
                                style="
                                  width: 100%;
                                  height: 150px;
                                  position: relative;
                                "
                              > -->
                            <div :style="ListImageAspectRatio(pro.style)">
                              <LazyImage
                                :src="product.image"
                                alt=""
                                style="
                                  position: absolute;
                                  top: 0;
                                  left: 0;
                                  bottom: 0;
                                  right: 0;
                                  height: 100%;
                                  width: 100%;
                                "
                                :style="
                                  getImageFit(pro.style) +
                                  getImageTopBorderCSs(pro.style)
                                "
                              />
                              <!-- getImageRatio(pro.style) + -->
                            </div>
                            <div
                              class="mt-2 flex-grow-1"
                              :style="getPaddingCss(pro.style)"
                            >
                              <div
                                class="d-flex flex-column justify-content-between h-100"
                              >
                                <!-- Product Title -->
                                <div class="mb-2">
                                  <p
                                    class="mb-0"
                                    :style="getTitleStyles(pro.style)"
                                  >
                                    {{
                                      getProductInfoDetail(
                                        pro.useProductVariant,
                                        product,
                                        "title"
                                      )
                                    }}
                                  </p>
                                  <!-- Rating Div start -->
                                  <div
                                    v-if="
                                      product.productReview &&
                                      isJudgeMeInstalled
                                    "
                                    class="Review"
                                    v-html="product.productReview"
                                    :style="getPriceColor(pro.style)"
                                  ></div>
                                  <!-- Rating Div end -->
                                </div>
                                <!-- Product Price , features & buy button -->
                                <div class="">
                                  <div
                                    v-if="
                                      !pro.hideDescription &&
                                      !pro.showHeirarchyDesciption
                                    "
                                  >
                                    <!-- normal Description Start -->
                                    <div
                                      :style="
                                        getDescriptionStyles(isDesktopView)
                                      "
                                      v-if="!pro.useBulletDescription"
                                    >
                                      <p
                                        class="mb-0"
                                        :style="getPriceColor(pro.style)"
                                      >
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis
                                        nostrud exercitation ullamco laboris
                                        nisi ut aliquip ex ea commodo consequat.
                                        ng elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna.
                                      </p>
                                    </div>
                                    <!-- normal Description End -->

                                    <!-- Bullet Description Start -->
                                    <div v-else>
                                      <div
                                        v-for="(
                                          features, index2
                                        ) in product.productAdvantage"
                                        :key="index2"
                                      >
                                        <div
                                          class="d-flex align-items-center mt-1"
                                          :style="getBulletStyles(pro.style)"
                                        >
                                          <div class="mr-3">
                                            <i
                                              class="fa"
                                              :class="pro.selectedBulletIcon"
                                            ></i>
                                          </div>
                                          <span>{{ features.text }}</span>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- Bullet Description End -->
                                  </div>

                                  <div class="mt-auto">
                                    <p
                                      class="m-0 p-0 mt-3"
                                      v-if="!isHidePrice"
                                      :style="getPriceColor(pro.style)"
                                    >
                                      <span
                                        v-if="currencyPos == 'left'"
                                        :class="currencySpace ? 'mr-1' : null"
                                        >{{ currentCurrency }}</span
                                      >{{
                                        getProductInfoDetail(
                                          pro.useProductVariant,
                                          product,
                                          "price"
                                        )
                                      }}<span
                                        v-if="currencyPos == 'right'"
                                        :class="currencySpace ? 'ml-1' : null"
                                        >{{ currentCurrency }}</span
                                      >
                                    </p>

                                    <div class="mt-2">
                                      <div
                                        class="pb-2"
                                        v-if="
                                          pro.useProductVariant &&
                                          product &&
                                          product.variants
                                        "
                                      >
                                        <VaraintSelectComp
                                          :pro="pro"
                                          :product="product"
                                        />
                                      </div>

                                      <button
                                        v-if="pro.useAddToCart"
                                        :style="getProductButtonCss(pro.style)"
                                      >
                                        {{
                                          pro.useAddToCartText || "Add to Cart"
                                        }}
                                      </button>
                                      <button
                                        v-else
                                        :style="getProductButtonCss(pro.style)"
                                      >
                                        {{ pro.buttonText }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Detail- List View end-->
                      <!-- List View start-->
                      <div class="row" v-if="getViewType == 'list'">
                        <div
                          :class="
                            isDesktopView
                              ? `col-${12 / pro.productsPerPage}`
                              : `col-${12 / pro.productsPerPageMobile}`
                          "
                          class="mb-3"
                          v-for="(product, index) in SampleProducts"
                          :key="index"
                          v-show="index < displayProductsLength(pro)"
                        >
                          <!-- style="max-width:211px;" -->
                          <MultipleViewSingleCard
                            :pro="pro"
                            :index="index"
                            :isTest="true"
                            :isDesktopView="isDesktopView"
                            :productsLength="SampleProducts.length"
                            :product="product"
                            :displayProductsLength="displayProductsLength"
                            :isJudgeMeInstalled="isJudgeMeInstalled"
                            :isHidePrice="isHidePrice"
                            :CurrencySign="'$'"
                            :currencyPos="currencyPos"
                            :currencySpace="currencySpace"
                            :disableAddtoCartBtn="true"
                            :AddToCart="AddToCart"
                            :goToURLClicked="goToURLClicked"
                            :getProductInfoDetail="getProductInfoDetail"
                            :checkFileType="checkFileType"
                            :getImageFit="getImageFit"
                            :getImageTopBorderCSs="getImageTopBorderCSs"
                            :getTitleStyles="getTitleStyles"
                            :getPriceColor="getPriceColor"
                          />
                        </div>
                      </div>
                      <!-- List View end-->
                      <!-- Flip Cards start-->
                      <div class="row" v-if="getViewType == 'flipCards'">
                        <div
                          :class="
                            isDesktopView
                              ? `col-${12 / pro.productsPerPage}`
                              : `col-${12 / pro.productsPerPageMobile}`
                          "
                          class="mb-3"
                          v-for="(product, index) in SampleProducts"
                          :key="index"
                          v-show="index < displayProductsLength(pro)"
                        >
                          <!-- style="max-width:211px;" -->
                          <FlipCards
                            :pro="pro"
                            :index="index"
                            :isTest="true"
                            :isDesktopView="isDesktopView"
                            :productsLength="SampleProducts.length"
                            :product="product"
                            :displayProductsLength="displayProductsLength"
                            :isJudgeMeInstalled="isJudgeMeInstalled"
                            :isHidePrice="isHidePrice"
                            :CurrencySign="'$'"
                            :currencyPos="currencyPos"
                            :currencySpace="currencySpace"
                            :disableAddtoCartBtn="true"
                            :AddToCart="AddToCart"
                            :goToURLClicked="goToURLClicked"
                            :getProductInfoDetail="getProductInfoDetail"
                            :checkFileType="checkFileType"
                            :getImageFit="getImageFit"
                            :getImageTopBorderCSs="getImageTopBorderCSs"
                            :getTitleStyles="getTitleStyles"
                            :getPriceColor="getPriceColor"
                          />
                        </div>
                      </div>
                      <!-- Flip Cards end-->

                      <!-- Group Products View Start -->

                      <div
                        class="row m-0 p-0"
                        v-if="getViewType == 'groupProducts'"
                      >
                        <div class="row w-100 m-0 p-0">
                          <GroupProductsComp
                            :pro="pro"
                            :isTest="true"
                            :isDesktopView="isDesktopView"
                            :products="SampleProducts"
                            :displayProductsLength="displayProductsLength"
                            :isJudgeMeInstalled="isJudgeMeInstalled"
                            :isHidePrice="isHidePrice"
                            :CurrencySign="'$'"
                            :currencyPos="currencyPos"
                            :currencySpace="currencySpace"
                            :disableAddtoCartBtn="true"
                            :AddToCart="AddToCart"
                            :goToURLClicked="goToURLClicked"
                            :getProductInfoDetail="getProductInfoDetail"
                            :checkFileType="checkFileType"
                            :getImageFit="getImageFit"
                            :getImageTopBorderCSs="getImageTopBorderCSs"
                            :getTitleStyles="getTitleStyles"
                            :getPriceColor="getPriceColor"
                          />
                        </div>
                      </div>
                      <!-- Group Products View End -->
                    </div>
                    <div
                      v-if="pro.type == 'button'"
                      :style="
                        'display:flex;justify-content:' +
                        pro.style.position +
                        ';'
                      "
                    >
                      <button
                        :style="getButtonCss(pro.style, isDesktopView)"
                        @click="handleBtnClicked(pro)"
                      >
                        <span
                          :style="{
                            opacity: `${pro.style.textOpacity}%`,
                            fontSize: `${pro.style.fontSize}px`,
                          }"
                        >
                          {{ pro.text }}
                        </span>
                        {{ pro.style.alignItems }}
                      </button>
                    </div>

                    <SocialLinksComp
                      v-if="pro.type == 'socialLinks'"
                      :pro="pro"
                      :isDesktopView="isDesktopView"
                    />
                    <ShareLinksComp
                      v-if="pro.type == 'shareLink'"
                      :pro="pro"
                      :isDesktopView="isDesktopView"
                    />
                    <CouponComp
                      v-if="pro.type == 'coupon'"
                      :pro="pro"
                      :isDesktopView="isDesktopView"
                      :pageBgColor="
                        CurrentPage.pageDesign.pageStyles.backgroundColor
                      "
                      :isLive="false"
                    />
                  </div>
                </div>
              </draggable>
            </div>
            <div v-else>
              <draggable
                v-model="pageDesignNoResultBlocks"
                class="dragArea list-group"
                group="actions"
                v-bind="dragOptions"
                chosen-class="chosen"
                drag-class="drag"
                @start="noResultDragging = true"
                @end="noResultDragging = false"
                style="cursor: move"
              >
                <div
                  v-for="(pro, ind) in pageDesign.NoResultBlocksArray"
                  :key="'preview' + ind"
                  draggable="false"
                  :class="noResultDragging ? 'dragElementBorder' : null"
                  @click="changeCustomize(ind)"
                  @drop="handleElementDrop($event, ind)"
                  @dragover.prevent
                  @dragenter.prevent
                  @mouseover="addHoverPorperty(ind)"
                  @mouseleave="removeHoverPorperty(ind)"
                >
                  <div
                    id="preview"
                    :style="getHeightWidth(pro.style) + getDivBorderCss(pro)"
                    class="content"
                    style="position: relative"
                  >
                    <EditorAction
                      v-if="pro.customizeStatus"
                      :deleteCondition="getDeleteButtonCondition(pro)"
                      :currElementIndex="ind"
                      :currElementType="pro.type"
                    />
                    <div
                      v-if="pro.type == 'text'"
                      :style="
                        getColorCss(pro.style) +
                        getPaddingCss(pro.style) +
                        getMarginCss(pro.style, isDesktopView) +
                        getColorWithOpacity(pro.style) +
                        getTextRotate(pro.style) +
                        getLineHeight(pro.style) +
                        getTextAlign(pro.style) +
                        getFontStyle(pro.style, isDesktopView)
                      "
                      v-html="getEditorPersonalisedValueStyle(pro.content)"
                    ></div>

                    <!-- </VueDragResize> -->
                    <div
                      v-if="pro.type == 'button'"
                      :style="
                        'display:flex;justify-content:' +
                        pro.style.position +
                        ';'
                      "
                    >
                      <button
                        :style="getButtonCss(pro.style, isDesktopView)"
                        @click="handleBtnClicked(pro)"
                      >
                        <span
                          :style="{
                            opacity: `${pro.style.textOpacity}%`,
                            fontSize: `${pro.style.fontSize}px`,
                          }"
                        >
                          {{ pro.text }}
                        </span>
                        {{ pro.style.alignItems }}
                      </button>
                    </div>

                    <SocialLinksComp
                      v-if="pro.type == 'socialLinks'"
                      :pro="pro"
                      :isDesktopView="isDesktopView"
                    />
                    <ShareLinksComp
                      v-if="pro.type == 'shareLink'"
                      :pro="pro"
                      :isDesktopView="isDesktopView"
                    />
                    <div v-if="pro.type == 'imageComp'">
                      <InlineImageComp
                        :pro="pro"
                        :isDesktopView="isDesktopView"
                      />
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0"
        v-show="showRightImage && pageDesign.pageType !== 'resultPage'"
        :class="{
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division': isDesktopView,
        }"
        :style="
          calculateImageWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive
          )
        "
      >
        <div
          @click="activeImageCustomize"
          :class="isDesktopView ? 'image-division' : ''"
        >
          <div :style="height100(isDesktopView)">
            <LazyImage
              :key="ImgBlockImageSrc"
              :style="
                getUniversalImageStyle(
                  isDesktopView,
                  pageDesign.imageBlock.style,
                  IsImageVariableWidthActive,
                  pageDesign.imageBlock
                ) +
                getImageOpacity(pageDesign.imageBlock.style) +
                getImageRotation(pageDesign.imageBlock.style)
              "
              :class="[
                `pic-${getSelectedPage.order}`,
                ImgBlockImageSrc == '/images/default_start_page.png'
                  ? 'default-bg'
                  : '',
              ]"
              :src="MainImgSrc(ImgBlockImageSrc)"
              alt
            />
          </div>
        </div>
        <button id="clear" class="hide close-icon" @click="deleteImage">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
              fill="black"
              fill-opacity="0.5"
            />
            <path
              d="M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import centralEventBus from "./centralEventBus";
import HtmlElement from "../../../quizPreviewComp/HtmlElement.vue";
import draggable from "vuedraggable";
import cssFunctionMixin from "../../mixin/cssFunction.js";
import { mapGetters, mapMutations } from "vuex";
import { Carousel, Slide } from "vue-carousel";
import SocialLinksComp from "../../../quizPreviewComp/SocialLinksComp.vue";
import ShareLinksComp from "../../../quizPreviewComp/ShareLinksComp.vue";
import CouponComp from "../../../quizPreviewComp/CouponComp.vue";
import axios from "axios";
// import VaraintSelectComp from "../../../quizPreviewComp/VaraintSelectComp.vue";

import EditorAction from "../../../../components/EditorActions.vue";
export default {
  props: ["isDesktopView"],

  mixins: [cssFunctionMixin],
  components: {
    draggable,
    Carousel,
    Slide,
    SocialLinksComp,
    ShareLinksComp,
    HtmlElement,
    CouponComp,
    // VaraintSelectComp,
    FlipCards: () =>
      import(
        /* webpackChunkName: "FlipCards" */ "../../../quizPreviewComp/components/ResultPage/FlipCards.vue"
      ),
    MultipleViewSingleCard: () =>
      import(
        /* webpackChunkName: "MultipleViewSingleCard" */ "../../../quizPreviewComp/components/ResultPage/MultipleViewSingleCard.vue"
      ),
    GroupProductsComp: () =>
      import(
        /* webpackChunkName: "GroupProductsComp" */ "../../../quizPreviewComp/components/ResultPage/GroupProductsComp.vue"
      ),
    VaraintSelectComp: () =>
      import(
        /* webpackChunkName: "VaraintSelectComp" */ "../../../quizPreviewComp/VaraintSelectComp.vue"
      ),
    EditorAction,
    InlineImageComp: () =>
      import(
        /* webpackChunkName: "InlineImageComp" */ "../../../quizPreviewComp/components/InlineImageComp.vue"
      ),
  },
  data() {
    return {
      UpdateCount: 0,
      editMode: false,
      dragging: false,
      noResultDragging: false,
      activeSideBar: "pageStyle",
      count: 0,
      keyValue: 0,
      SelectedValue: "",
      SampleProducts: [
        {
          productId: 1,
          image: "https://images.quizell.com/gallery/newProductImage.svg",
          title: "Product  #1 with a big title.",
          price: "250",
          productReview:
            "<div style='display:none' class='jdgm-prev-badge' data-average-rating='5.00' data-number-of-reviews='2' data-number-of-questions='0'> <span class='jdgm-prev-badge__stars' data-score='5.00' tabindex='0' aria-label='5.00 stars' role='button'> <span class='jdgm-star jdgm--on'></span><span class='jdgm-star jdgm--on'></span><span class='jdgm-star jdgm--on'></span><span class='jdgm-star jdgm--on'></span><span class='jdgm-star jdgm--on'></span> </span> <span class='jdgm-prev-badge__text'> 2 reviews </span> </div>",
          productAdvantage: [
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
          ],
          bullet_description: [
            "Some Advantage",
            "Some Advantage",
            "Some Advantage",
            "Some Advantage",
          ],
          selectedVariant: "",
          variants: [
            {
              compare_at_price: 330,
              id: 1,
              image: null,
              price: 150,
              product_id: 75091,
              shopify_id: 421895,
              sku: "",
              title: "Variant 1",
            },
            {
              compare_at_price: 400,
              id: 2,
              image: null,
              price: 350,
              product_id: 75092,
              shopify_id: 421895401,
              sku: "",
              title: "Variant 2",
            },
          ],
          detail_link: "https://www.quizell.com",
          tags: ["tag1"],
        },
        {
          productId: 2,
          image: "https://images.quizell.com/gallery/newProductImage.svg",
          title: "Product  #2 ",
          price: "250",
          productReview:
            '<div style="display:none" class="jdgm-prev-badge" data-average-rating="3.67" data-number-of-reviews="3" data-number-of-questions="0"> <span class="jdgm-prev-badge__stars" data-score="3.67" tabindex="0" aria-label="3.67 stars" role="button"> <span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--half"></span><span class="jdgm-star jdgm--off"></span> </span> <span class="jdgm-prev-badge__text"> 3 reviews </span> </div>',
          productAdvantage: [
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
          ],
          bullet_description: [
            "Some Advantage",
            "Some Advantage",
            "Some Advantage",
            "Some Advantage",
          ],
          detail_link: "https://www.quizell.com",
          tags: ["tag2"],
        },
        {
          productId: 3,
          image: "https://images.quizell.com/gallery/newProductImage.svg",
          title: "Product  #3 ",
          price: "250",
          productReview:
            '<div style="display:none" class="jdgm-prev-badge" data-average-rating="3.67" data-number-of-reviews="3" data-number-of-questions="0"> <span class="jdgm-prev-badge__stars" data-score="3.67" tabindex="0" aria-label="3.67 stars" role="button"> <span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--half"></span><span class="jdgm-star jdgm--off"></span> </span> <span class="jdgm-prev-badge__text"> 3 reviews </span> </div>',
          productAdvantage: [
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
          ],
          bullet_description: [
            "Some Advantage",
            "Some Advantage",
            "Some Advantage",
            "Some Advantage",
          ],
          detail_link: "https://www.quizell.com",
          tags: ["tag3"],
        },
        {
          productId: 4,
          image: "https://images.quizell.com/gallery/newProductImage.svg",
          title: "Product  #4 ",
          price: "250",
          productReview:
            '<div style="display:none" class="jdgm-prev-badge" data-average-rating="3.67" data-number-of-reviews="3" data-number-of-questions="0"> <span class="jdgm-prev-badge__stars" data-score="3.67" tabindex="0" aria-label="3.67 stars" role="button"> <span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--half"></span><span class="jdgm-star jdgm--off"></span> </span> <span class="jdgm-prev-badge__text"> 3 reviews </span> </div>',
          productAdvantage: [
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
          ],
          bullet_description: [
            "Some Advantage",
            "Some Advantage",
            "Some Advantage",
            "Some Advantage",
          ],
          detail_link: "https://www.quizell.com",
          tags: ["tag4"],
        },
      ],
      isJudgeMeInstalledInQuiz: false,
    };
  },
  methods: {
    handleBtnClicked(pro) {
      const { buttonType, buttonLink } = pro;

      if (
        ["restart", "addAllToCart", "mailToOwner", "mailToCustomer"].includes(
          buttonType
        )
      ) {
        return;
      }

      if (["goToUrl", "goToCurrentTab"].includes(buttonType) && buttonLink) {
        let url = buttonLink;
        const pattern = /^((http|https|ftp):\/\/)/;

        if (!pattern.test(url)) {
          url = `http://${url}`;
        }
        buttonType === "goToUrl"
          ? window.open(url)
          : (window.location.href = url);
      }
    },
    goToURLClicked(url) {
      let URL = url;
      let pattern = /^((http|https|ftp):\/\/)/;
      if (URL !== "") {
        if (!pattern.test(URL)) {
          URL = "http://" + URL;
        }
        window.open(URL);
      }
    },
    handleElementDrop(evt, index) {
      const item = evt.dataTransfer.getData("item");
      if (item) {
        let obj = {
          index: index,
          item: item,
          currPage: this.getSelectedPage.value,
        };
        centralEventBus.$emit("handleAddElementDrop", obj);
        localStorage.setItem("DragStart", false);
      }
    },
    AddToCart() {
      // dummy function
      return true;
    },

    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },

    getProductInfoDetail(isProductVarient, product, value) {
      if (isProductVarient) {
        return product.selectedVariant && product.selectedVariant != ""
          ? product.selectedVariant[value]
          : product[value];
        // return product.selectedVariant.title;
      } else {
        return product[value];
      }
    },
    getMoreOptionText(value) {
      if (value) {
        return value;
      } else {
        return "More options";
      }
    },
    checkProductLength(arr) {
      return arr.length > 1 ? true : false;
    },
    ...mapMutations(["setImageUpload", "setCurrSidebarView"]),
    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; color:" +
        this.pageDesign.pageStyles.color +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; height:" +
        this.pageDesign.pageStyles.height +
        "px; " +
        (this.getEditorPageView == "desktop"
          ? "width:" + this.pageDesign.pageStyles.width + "px;"
          : "width:350px;");

      return design;
    },
    async changeCustomize(index) {
      await this.setCurrSidebarView("pageDesign");
      centralEventBus.$emit("changeCustomize", index);
    },
    addHoverPorperty(index) {
      centralEventBus.$emit("addHover", index);
    },

    removeHoverPorperty(index) {
      centralEventBus.$emit("removeHover", index);
    },
    getDivBorderCss(block) {
      if (block.customizeStatus) return "border:2px solid #4065d5";
      else if (block.hoverStatus) return "border:2px solid #ada9a9";
      else return "border:2px solid transparent";
    },

    deleteBlock(index) {
      centralEventBus.$emit("deleteBlock", index);
    },
    async activeImageCustomize() {
      await this.setCurrSidebarView("pageDesign");
      centralEventBus.$emit("activeImageCustomize");
    },

    addHoverOnImageBlock() {
      if (this.pageDesign.imageBlock.customizeStatus == true)
        this.pageDesign.imageBlock.hoverStatus = true;
    },
    removeHoverOnImageBlock() {
      this.pageDesign.imageBlock.hoverStatus = false;
    },
    deleteImage() {
      centralEventBus.$emit("deleteImage");
    },

    getbackgroundImage(IsImageAdded, property, imageAlign) {
      let backgroundPos;
      if (property.style.xCord && property.style.xCord) {
        backgroundPos = `background-position:${property.style.xCord}% ${property.style.yCord}%;`;
      } else {
        backgroundPos = `background-position:50% 50%;`;
      }

      if (
        this.pageDesign.pageType == "resultPage" &&
        IsImageAdded &&
        property.src !== ""
      ) {
        let filter;
        if (property.style.imageOpacity >= 0) {
          filter = `filter: brightness(${
            property.style.imageOpacity / 100
          }) contrast(${2 - property.style.imageOpacity / 100}) ;`;
        } else {
          filter = `filter: brightness(${
            property.style.imageOpacity / 100
          })  ;`;
        }

        return `background-image: url(${property.src}); background-repeat: no-repeat; background-size:  cover; ${backgroundPos} ${filter}`;
      } else if (
        this.pageDesign.pageType == "noResultPage" &&
        IsImageAdded &&
        property.noResultSrc !== "" &&
        imageAlign == "no"
      ) {
        let filter;
        if (property.style.imageOpacity >= 0) {
          filter = `filter: brightness(${
            property.style.imageOpacity / 100
          }) contrast(${2 - property.style.imageOpacity / 100}) ;`;
        } else {
          filter = `filter: brightness(${
            property.style.imageOpacity / 100
          })  ;`;
        }

        return `background-image: url(${property.noResultSrc}); background-repeat: no-repeat; background-size:  cover; ${backgroundPos} ${filter}`;
      }
    },
    getDeleteButtonCondition(pro) {
      if (pro.customizeStatus) {
        // if (pro.type == "text" && this.minTextCount <= 1) return false;
        if (pro.type == "product") return false;
        return true;
      }
    },

    getTitleStyles(property) {
      return (
        `color:${property?.titleColor || "#000"};opacity:${
          property?.titleTextOpacity || 100
        }%;` +
        " font-weight:" +
        property.fontWeight +
        "; font-style:" +
        property.fontStyle +
        ";text-decoration:" +
        property.textDecoration +
        ";font-size:" +
        property.fontSize +
        "px;font-family:" +
        property.fontFamily +
        "; text-align:" +
        property.textAlign +
        ";"
      );
    },
    getFontFamily(property) {
      return "font-family:" + property.fontFamily + ";";
    },
    getCardBackground(property) {
      return "background-color:" + property.backgroundColor + ";";
    },
    getPriceColor(property) {
      return `color:${property.priceColor};text-align:${property.textAlign};font-family:${property.fontFamily};`;
    },
    getCardBorderCSS(property) {
      return (
        " border-radius:" +
        property.borderRadius +
        "px; border:" +
        property.borderSize +
        "px solid " +
        property.borderColor +
        ";"
      );
    },
    getPagePadding(property, isDesktopView) {
      const getPaddingValue = (desktopValue, mobileValue) =>
        isDesktopView || mobileValue === undefined ? desktopValue : mobileValue;

      const paddingTop = getPaddingValue(
        property.pagePaddingTop,
        property.pageMobilePaddingTop
      );
      const paddingRight = getPaddingValue(
        property.pagePaddingRight,
        property.pageMobilePaddingRight
      );
      const paddingBottom = getPaddingValue(
        property.pagePaddingBottom,
        property.pageMobilePaddingBottom
      );
      const paddingLeft = getPaddingValue(
        property.pagePaddingLeft,
        property.pageMobilePaddingLeft
      );

      return `; text-align: ${property.textAlign}; padding: ${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px; `;
    },
    getImageTopBorderCSs(property) {
      return (
        " border-top-left-radius:" +
        property.borderRadius +
        "px; border-top-right-radius:" +
        property.borderRadius +
        "px ; "
      );
    },
    getImageRatio(property) {
      let imageRatioCSS;
      if (property.imageRatio === "square") {
        imageRatioCSS = "";
      } else if (property.imageRatio === "potrait") {
        //  imageRatioCSS = `position:absolute;height:100%; width:66%; margin:auto; left:0; right:0;`
        imageRatioCSS = `position:absolute;height:auto; width:66%; margin:auto; left:0; right:0;`;
      } else {
        imageRatioCSS = `position:absolute; width:100%; height:66%; margin:auto; top:0; bottom:0;`;
      }

      return " width: 100% " + ";" + "height: 150px" + ";" + imageRatioCSS;
    },
    getImageFit(property) {
      let imageFitCSS;
      if (property.imageFit === "Fit") {
        imageFitCSS = "object-fit: contain" + ";";
      } else if (property.imageFit === "Fill") {
        imageFitCSS = "object-fit: fill" + ";";
      }
      return imageFitCSS;
    },

    displayProductsLength(element) {
      let totalProducts;
      if (this.isDesktopView) {
        totalProducts = element.productsPerPage * element.rows;
      } else {
        totalProducts = element.mobileRows
          ? element.productsPerPageMobile * element.mobileRows
          : element.productsPerPageMobile * element.rows;
      }
      return totalProducts;
    },
    getDetailListProducts(arr) {
      if (arr.length > 1) {
        const newArr = [...arr];
        newArr.shift();
        return newArr;
      }
    },

    async getAppInstalledByQuiz(handleKey) {
      try {
        let data = {
          handle_key: handleKey,
          quiz_id: localStorage.getItem("QuizID"),
          quizId: parseInt(localStorage.getItem("QuizID")),
        };

        const response = await axios.get(`/getAppInstalledToQuiz`, {
          params: data,
        });
        if (response.status == 200 && response.data.ok) {
          this.isJudgeMeInstalledInQuiz = response.data.installed;
          localStorage.setItem("judgme_installed", response.data.installed);
        }
      } catch (error) {
        if (error) {
          throw error;
        }
      }
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        chosenClass: "custom-chosen",
      };
    },
    pageDesign() {
      return this.getSelectedPage.pageDesign;
    },
    getViewType() {
      const { useSeperateMobileViewType, viewType, mobileViewType } = 
    this.pageDesign.blocksArray[this.getProductRankOptionArrayIndex];

  return (useSeperateMobileViewType && !this.isDesktopView && mobileViewType) 
    ? mobileViewType 
    : viewType;
    },
    pageDesignBlocks: {
      get() {
        return this.pageDesign.blocksArray;
      },
      set(newValue) {
        centralEventBus.$emit("handleBlocksArrayDraggable", newValue);
      },
    },
    pageDesignNoResultBlocks: {
      get() {
        return this.pageDesign.NoResultBlocksArray;
      },
      set(newValue) {
        centralEventBus.$emit("handleNoResultBlocksArrayDraggable", newValue);
      },
    },
    getPageType() {
      return localStorage.getItem("default_result_Page_state");
    },
    isJudgeMeInstalled() {
      return this.isJudgeMeInstalledInQuiz || this.isJudgeMeInstalledInQuiz == 1
        ? true
        : false;
    },
    getImageAlign(){
      if (this.pageDesign.pageStyles.useMobileImageAlign && !this.isDesktopView) {
     return  this.pageDesign.pageStyles.mobileImageAlign ;
    } else {
      return this.pageDesign.pageStyles.imageAlign;
    }
    },
    showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.getImageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.getImageAlign == "left" ||
            this.getImageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.getImageAlign == "right"
      );
    },
    ...mapGetters([
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getEditorPageView",
      "getEditorSettings",
      "getPlanData",
      "getPageZoom",
    ]),
    pageZoomValue() {
      return this.getPageZoom;
    },
    UserPlanData() {
      return this.getPlanData;
    },
    CurrentPage() {
      return this.getSelectedPage;
    },
    currentCurrency() {
      return this.getEditorSettings.selectedCurrency.sign;
    },
    currencySpace() {
      if (this.getEditorSettings.selectedCurrency.sign == "RON") return true;
      return false;
    },
    isHidePrice() {
      return this.getEditorSettings.hidePrice == 1 ? true : false;
    },
    maxButtonLimit() {
      let count = 0;
      if (this.pageDesign.pageType == "resultPage") {
        this.pageDesign.blocksArray.forEach(
          (x) => x.type == "button" && count++
        );
      } else {
        this.pageDesign.NoResultBlocksArray.forEach(
          (x) => x.type == "button" && count++
        );
      }
      return count;
    },
    maxSocialLinks() {
      let count = 0;
      if (this.pageDesign.pageType == "resultPage") {
        this.pageDesign.blocksArray.forEach(
          (x) => x.type == "socialLinks" && count++
        );
      } else {
        this.pageDesign.NoResultBlocksArray.forEach(
          (x) => x.type == "socialLinks" && count++
        );
      }
      return count;
    },
    CurrentPageType() {
      return this.pageDesign.pageType;
    },
    IsImageAdded() {
      if (this.CurrentPageType == "noResultPage") {
        if (
          this.isNoResultSrcPresent &&
          this.pageDesign.imageBlock.noResultSrc != ""
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.pageDesign.imageBlock.src !== "") return true;
        return false;
      }
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.getImageAlign != "no"
        ? true
        : false;
    },
    isNoResultSrcPresent() {
      if (
        Object.prototype.hasOwnProperty.call(
          this.pageDesign.imageBlock,
          "noResultSrc"
        )
      )
        return true;
      return false;
    },
    ImgBlockImageSrc() {
      const selectImage = (defaultSrc, mobileSrc) => {
        if (this.isDesktopView) {
          return defaultSrc;
        }
        return this.pageDesign.imageBlock.useMobileImage
          ? mobileSrc
          : defaultSrc;
      };

      if (
        this.CurrentPageType === "resultPage" &&
        this.pageDesign.imageBlock.src !== ""
      ) {
        return selectImage(
          this.pageDesign.imageBlock.src,
          this.pageDesign.imageBlock.useMobileImageSrc
        );
      }

      if (
        this.CurrentPageType === "noResultPage" &&
        this.isNoResultSrcPresent &&
        this.pageDesign.imageBlock.noResultSrc !== ""
      ) {
        return selectImage(
          this.pageDesign.imageBlock.noResultSrc,
          this.pageDesign.imageBlock.useMobileImageSrc
        );
      }

      return "";
    },
    imageAddedLayout() {
      if (this.getImageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      if (this.CurrentPageType == "resultPage") return "col-12";
      if (
        this.CurrentPageType == "noResultPage" &&
        this.pageDesign.imageBlock.noResultSrc == ""
      )
        return "col-12";
      return "col-12 col-xs-12 col-sm-6 col-md-6 ";
    },
    minTextCount() {
      let count = 0;
      if (this.pageDesign.pageType == "resultPage") {
        this.pageDesign.blocksArray.forEach((x) => x.type == "text" && count++);
      } else {
        this.pageDesign.NoResultBlocksArray.forEach(
          (x) => x.type == "text" && count++
        );
      }
      return count;
    },
    QuestionTitleExist() {
      const tempArray = [];
      this.pageDesign.blocksArray.forEach((x) => {
        if (x.type == "text") {
          tempArray.push(x);
        }
      });
      const questionTitleExist = (element) => element.questionTitle === true;
      return tempArray.some(questionTitleExist);
    },
    getProductRankOptionArrayIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "product"
      );

      if (index > -1) {
        return index;
      } else {
        return null;
      }
    },
    getSocialLinksIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "socialLinks"
      );

      if (index > -1) {
        return index;
      } else {
        return null;
      }
    },

    currencyPos() {
      const index = this.getProductRankOptionArrayIndex;
      if (index) {
        if (this.pageDesign.blocksArray[index].style.Currencyposition) {
          return this.pageDesign.blocksArray[index].style.Currencyposition;
        }
        return "left";
      }
      return null;
    },
    createRankOptionArray() {
      const rankOptionArray = [
        {
          text: "1st",
        },
        {
          text: "2nd",
        },
        {
          text: "3rd",
        },
      ];

      return rankOptionArray;
    },
    getProductsList() {
      const { limitProduct, limitProductLength } =
        this.pageDesign.blocksArray[this.getProductRankOptionArrayIndex];

      if (limitProduct && limitProductLength > 0) {
        const Products = [...this.SampleProducts];
        return Products.slice(0, limitProductLength);
      } else {
        return this.SampleProducts;
      }
    },
  },

  mounted() {
    if (!localStorage.getItem("judgme_installed")) {
      this.getAppInstalledByQuiz("judgeme");
    }
  },
};
</script>
<style scoped>
.images-size label {
  color: #b3afb6;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  background-repeat: no-repeat !important;
  background-position-x: 100% !important;
  background-position-y: 5px !important;
  padding-right: 2rem;
}
.custom-select-aanswer.result-select {
  border: none !important;
  width: 100%;
  padding-left: 0px;
}
.result-select {
  padding-left: 35px;
  background: #f4f3f5;
  height: inherit;
  border: none !important;
}
.custom-select-aanswer {
  display: flex;
  border: 1px solid #000000;
  border-radius: 6px;
  height: 36px;
  /* background:#ededee; */
  align-items: center;
}
.custom-select-aanswer img {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 24px;
}
.question-type-select {
  background-color: transparent;
  border: none;
  padding: 4px 39px !important;
  height: 36px;
  width: 100%;
}

.big-div {
  padding: 0px;
}
@media only screen and (max-width: 767px) {
  .big-div {
    width: 100% !important;
    padding: 0px 15px;
  }
}
@media only screen and (max-width: 1366px) {
  .big-div {
    padding: 0px 15px !important;
  }
}

/* Carousel CSS  */
.ProductCarousel >>> .VueCarousel-pagination {
  height: 40px !important;
  visibility: hidden !important;
}
.ProductCarousel >>> .VueCarousel-navigation {
  position: absolute;
  top: 95%;
  left: 50%;
}

/* Judge Me Css */
.Review >>> .jdgm-prev-badge {
  display: block !important;
}

.Review >>> .jdgm-star {
  display: inline !important;
  text-decoration: none !important;
  padding: 0 4px 0 0 !important;
  margin: 0 !important;
  font-weight: bold;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
}
.Review >>> .jdgm-preview-badge .jdgm-star.jdgm-star {
  color: #eab304;
}

.Review >>> .jdgm-star.jdgm--on:before {
  font-family: FontAwesome;
  content: "\f005";
  color: #eab304;
}
.Review >>> .jdgm-star.jdgm--half:before {
  font-family: FontAwesome;
  content: "\f123";
  color: #eab304;
}
.Review >>> .jdgm-star.jdgm--off:before {
  font-family: FontAwesome;
  content: "\f006";
  color: #eab304;
}
</style>
