<template>
    <div
     
      :style="
        getMarginCss(pro.style, isDesktopView) 
      "
    >
      <div class=" d-flex justify-content-between align-items-center  couponContainer" :style="getCouponContainerStyles(pro.style,pageBgColor)">

        <div style="flex:0.7">
            <p class="m-0 p-0" :style="
                          getColorCss(pro.style) +
                         
                          getColorWithOpacity(pro.style) +
                          getTextRotate(pro.style) +
                          getLineHeight(pro.style) +
                          getTextAlign(pro.style) +
                          getFontStyle(pro.style)
                        ">{{ pro.couponText }}</p>
        </div>
        <div class="  d-flex justify-content-center" style="flex:0.3">
            <button class="btn " @click="triggerCoupon" :style="getCouponBtnStyles(pro.style)" >{{ pro.couponBtnText }}</button>
        </div>

        

            
      </div>
    </div>
  </template>
  
  <script>
  import cssFunctionMixin from "../customize/mixin/cssFunction";
  // import { mapGetters } from "vuex";
  export default {
    mixins: [cssFunctionMixin],
    props: ["pro", "isDesktopView","pageBgColor","isLive"],
    data() {
      return {
        quizText: "Check out your quiz result on Quizell  ",
      };
    },
    methods: {
        getCouponContainerStyles(property,color){
            let pageBgColor = ""
            if(color != ''){
                pageBgColor = color
            } else {
                pageBgColor = `#fff`
            }

            return `
                    background:${property.couponBg}; --pageBgColor:${pageBgColor};
            `
        },
        getCouponBtnStyles(property){
            return `
             padding : 10px 20px; background:${property.btnBgColor};color:${property.btnColor};border-radius: 32px; text-align:${property.btnTextAlign};font-family:${property.btnFontFamily};font-style:${property.btnFontStyle};text-decoration:${property.btnTxtDecoration};font-size:${property.btnFontSize};font-weight:${property.btnFontWeight};
            `
        },

        triggerCoupon(){
            if(this.isLive && this.getCouponLink != ''){
              let pattern = /^((http|https|ftp):\/\/)/;
          let URL = this.getCouponLink
        if (!pattern.test(URL)) {
          URL = "https://" + URL;
        }
        window.open(URL,"_blank");
      
            }
        }
    },
    computed: {
            getCouponLink(){
                return this.pro.couponLink ? this.pro.couponLink : ''
            }
    },
  };
  </script>
  
  <style scoped>
 .couponContainer{
    border-radius: 6px;
    box-shadow: 0px 2px 3px 0px #00000040;
    position: relative;
    padding:20px 25px;

 }


 .couponContainer::after {
  position: absolute;
  content: "";
  height: 20px;
  right: -10px;
  border-radius: 50%;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--pageBgColor);
  width: 20px;
}

.couponContainer::before {
  position: absolute;
  content: "";
  height: 20px;
  left: -10px;
  border-radius: 50%;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  background-color:var(--pageBgColor);
  width: 20px;
}
  </style>
  